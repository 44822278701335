<template>
	<v-card>
		<v-card-text>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="d-flex align-center">
						<v-img
							:src="require('@/assets/logo/logo.png')"
							contain
							:max-width="isSmAndDown ? 70 : 90"
							:height="isSmAndDown ? 38 : 46"
						/>
						<strong class="text-xl">시험 응시 유의사항 안내</strong>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item two-line>
				<v-list-item-content>
					<v-list-item-title class="text-wrap">
						시험 응시 전
						<strong>모의고사</strong>
						를 응시해야 시험 응시가 가능합니다.
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-wrap">
						시험 응시 전 응시 환경에 휴대폰 카메라 및 PC or 노트북 웹캠이 설치
						되어있는지 확인하세요.
						<strong>카메라 환경이 미비될 시 시험 응시가 어렵습니다.</strong>
					</v-list-item-title>
					<v-list-item-subtitle />
				</v-list-item-content>
			</v-list-item>

			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-wrap">
						권장 사용환경:
						<br v-if="isSmAndDown" />
						<strong>PC or 노트북, 크롬(Chrome)브라우저</strong>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-wrap">
						시험 중 네트워크 설정 및 wifi 변경시 시험응시에 문제가 발생 될 수
						있으므로, 변경되지 않도록 주의 바랍니다
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-wrap">
						카페, 대중교통, 야외 등 인터넷 연결이 불안정한 장소에서 시험을
						실시할 경우 정상적으로 제출되지 않을 수 있으니, 최적의 인터넷
						환경에서 실시해 주시기 바랍니다.
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="text-wrap">
						비대면 시험 중 문제상황 발생시,
						<br v-if="isSmAndDown" />
						<strong>화면 캡쳐 등으로 증빙자료를 준비하시기 바랍니다.</strong>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-card-text>
		<v-card-actions class="d-flex justify-center">
			<v-btn large @click="routeToMockExam" color="secondary">
				모의고사 응시하기
			</v-btn>
			<v-btn
				x-large
				@click="routeToLicenseExam"
				color="primary"
				:disabled="!canTakeExam || profile.licenseIssues.length > 0"
			>
				<strong>시험 응시하기</strong>
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'

import store from '@/store'
import { useRouter, getVuetify } from '@core/utils'
import { warningSwal } from '@/plugins/swalMixin'

import MarketLicenseService from '@/services/MarketLicenseService'
import MarketCourseService from '@/services/MarketCourseService'

export default {
	components: {},
	setup() {
		const $vuetify = getVuetify()
		const { route, router } = useRouter()
		const courseId = route.value.params.courseId
		const profile = computed(() => store.getters['user/getMeDetail'])
		const firebaseUser = computed(() => store.getters['auth/getFirebaseUser'])
		const canTakeExam = ref(false)

		const getMyMarketCourseRegistrationDetail = async () => {
			try {
				const data =
					await MarketCourseService.getMyMarketCourseRegistrationDetail()

				if (data[0].canAttemptCount > 0) {
					canTakeExam.value = true
				} else {
					canTakeExam.value = false
				}

				const _registrationDetail = data.filter(
					e => e.marketCourse.id === parseInt(courseId),
				)

				if (!_registrationDetail[0].finished) {
					const confirm = await warningSwal('정상적인 경로가 아닙니다')
					if (confirm.isConfirmed) {
						router.go(-1)
					}
					return
				}
			} catch (e) {
				console.error(e)
			}
		}

		const getLicenseExamUserWorkbook = async () => {
			try {
				const data = await MarketLicenseService.getLicenseExamUserWorkbook({
					courseId: courseId,
					firebaseUid: firebaseUser.value.uid,
				})

				if (data && data.mockExam && !data.finished) {
					canTakeExam.value = true
				}
			} catch (e) {
				console.error(e)
			}
		}

		const routeToMockExam = () => {
			router.push({
				name: 'mypage-license-exam-auth',
				query: {
					isMockExam: true,
				},
				params: {
					courseId: courseId,
				},
			})
		}

		const routeToLicenseExam = () => {
			router.push({
				name: 'mypage-license-exam-auth',
				params: {
					courseId: courseId,
				},
			})
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		onMounted(async () => {
			await getMyMarketCourseRegistrationDetail()
			await getLicenseExamUserWorkbook()
		})

		return {
			profile,
			canTakeExam,
			isSmAndDown,

			routeToMockExam,
			routeToLicenseExam,
		}
	},
}
</script>

<style lang="scss" scoped></style>
